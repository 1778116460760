<template>
  <div class="select">
    <kendo-splitter
    :ref="'spitter'"
    class="select-main"
    :panes="hSplitter.panes"
    :orientation="hSplitter.orientation"
    :scrollable="hSplitter.scrollable">
      <SplitterContainer title="Filters" component="filters" selected="true"/>
      <SplitterContainer title="Citations List" component="citation-list" style="height: 100%; background: #f6f6f6;"/>
    </kendo-splitter>
  </div>
</template>

<script>
import SplitterContainer from '@/containers/SplitterContainer'

export default {
  name: 'review',
  components: {
    SplitterContainer
  },
  mounted: function () {
    this.splitterWidget = this.$refs.spitter.kendoWidget()
    this.splitterWidget.size('.k-pane:first', '20%')
  },
  data () {
    return {
      hSplitter: {
        scrollable: false,
        orientation: 'horizonal',
        panes: [
          { collapsible: true, size: '20%' },
          { collapsible: true, size: '80%' }
        ]
      }
    }
  }
}
</script>
